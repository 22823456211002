<template>
  <div class="contracttabel autobox2">
    <div class="fs-20 fw-b">合同待办</div>
    <div class="mt-27">
      <count
        :counts="state.counts"
        v-loading="countLoading"
        :element-loading-text="config.loading.text"
        :element-loading-spinner="config.loading.spinner"
        :element-loading-background="config.loading.background"
      ></count>
    </div>
    <div class="fs-20 fw-b mt-39 mb-27">待签署文件列表</div>
    <nodata style="margin-top: 110px;" title="当前暂无待签署文件～" v-show="state.Notice.length == 0" />
    <el-table
      v-show="state.Notice.length != 0"
      :data="state.Notice"
      class="mb-20"
      :header-cell-style="{
        background: 'var(--active-alpha)',
        color: config.table.color,
        fontWeight: config.table.fontWeight,
        fontSize: config.table.fontSize,
      }"
      row-key="id"
      @selection-change="selectionChange"
    >
      <!-- <el-table-column :align="'center'" prop="contractType" label="合同类型" /> -->
      <!-- <el-table-column :align="'center'" prop="initiatorStr" label="发起方" /> -->
      <el-table-column :align="'center'" prop="sponsorStr" label="发起人" />
      <!-- <el-table-column :align="'center'" prop="showSignatory" label="签署方" width="180" /> -->
      <el-table-column :align="'center'" prop="initiateData" label="发起日期" />
      <el-table-column :align="'center'" prop="contractName" label="合同标题" />
      <el-table-column
        :align="'center'"
        prop="contractDeadline"
        label="截止日期"
      />
      <el-table-column :align="'center'" label="操作" width="150px">
        <template #default="scope">
          <div class="dp-fc butbox fw-w">
            <div class="Editor_span" @click="showDetail(scope.row)">
              查看详情
            </div>
            <div
              class="Editor_span ml-20"
              @click="
                clickUserCheck(() => {
                  signClick(1, scope.row.id);
                })
              "
            >
              签署
            </div>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      v-show="state.Notice.length != 0"
      class="dp-f jc-c mt-20 mb-20"
      @current-change="handleCurrentChange"
      background
      layout="prev, pager, next,slot"
      :current-page="currentPage"
      :total="totalPage"
    >
    </el-pagination>
  </div>
  <Detailsdrawer ref="detailsRef" @withdrawEmit="getDataList()"></Detailsdrawer>
</template>
<script setup>
import { ref, unref, reactive } from "vue";
import count from "@/components/count/count.vue";
import Detailsdrawer from "@/views/contractDocuments/components/details.vue"; //详情
import nodata from "@/components/layout/nodata.vue";
import { queryResourceTable } from "@/api/cancelToken.js";
import { httpToken } from "@/utils/request";
import { router_push_name } from "@/utils/server/router.js";
import { clickUserCheck } from "@/utils/base/realname.js";
import { getXialaListdata2 } from "@/utils/server/selectdata.js";
import { add } from "@/utils/server/format";
import qs from "qs";
const loading = ref(false); //loading 显示
const countLoading = ref(false); //loading 显示
const currentPage = ref(1); //当前页数
const totalPage = ref(0); //总条数
const detailsRef = ref(); //详情弹框ref
let state = reactive({
  counts: getXialaListdata2(
    ["待我签署", "待他人签署", "即将截止", "草稿箱"],
    [0, 0, 0, 0]
  ),
  Notice: [],
});
// 获取统计数据
const getCountData = () => {
  countLoading.value = true;
  httpToken({
    method: "post",
    url: "/initiate-contract/contract/task",
  })
    .then((res) => {
      state.counts[0].value = res.data.doTask;
      state.counts[1].value = res.data.toTask;
      state.counts[2].value = res.data.becomeDue;
      state.counts[3].value = res.data.drafts;
      countLoading.value = false;
    })
    .catch((err) => {
      if (err.message != "stop") countLoading.value = false;
    });
};
getCountData();
// 展示详情数据
const showDetail = (row) => {
  unref(detailsRef).getDetails(row);
};
const signClick = (type = 0, id) => {
  // if (type == 1) {
  // 	if (userInfo.isCertification != 1) {
  // 		handleMessage('请先完成个人实名认证')
  // 		return
  // 	}
  // 	if (userInfo.isAuthentication != 1) {
  // 		handleMessage('请先完成企业实名')
  // 		return
  // 	}
  // }
  router_push_name("sign_details", { id: id, type: type }, undefined, true);
};
const handleCurrentChange = (e) => {
  getDataList(e);
};
const getDataList = (val = 1) => {
  let url = "/initiate-contract/contract/doTask";
  currentPage.value = val;
  state.Notice = [];
  loading.value = true;
  console.log("getDataList111", url);
  queryResourceTable(
    url,
    qs.stringify({
      page: val,
    })
  )
    .then((res) => {
      // 如果存在请求页码和返回数据时页面不同的情况 按返回页码重新请求
      if (val !== res.data.current) {
        getDataList(res.data.current);
        return;
      }
      totalPage.value = res.data.total;
      res.data.records.forEach((item) => {
        item.showSignatory = "";
        item.contractSignRecords.forEach((item2, ind2) => {
          item.initiatorStr = item.initiatorStr ? item.initiatorStr : "/";
          item.showSignatory += ind2 == 0 ? "" : ",";
          item.showSignatory +=
            item2.signerType == 1
              ? item2.contractSignPersonList[0].companyName
              : item2.contractSignPersonList[0].signerName;
        });
      });
      state.Notice = res.data.records;
      loading.value = false;
    })
    .catch((err) => {
      if (err.message != "stop") loading.value = false;
    });
};
getDataList();
</script>
<style lang="scss" scoped>
// @import "@/styles/general/element.scss"; //element 样式重置
@import "@/styles/general/element/table.scss"; //element 列表样式重置
@import "@/styles/general/element/pagination1.scss"; //element 分页样式重置

.contracttabel {
  width: 929px;
  background-color: #fff;
  padding: 20px 30px;
  .bottom {
    display: flex;
    justify-content: center;
  }
}
</style>